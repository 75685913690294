// Global Styling

html,
body {
    min-height: 100vh;
    margin: 0;
    background: $colorBackground;
    color: $colorOnBackground;
    font-family: $fontFamilyRegular;
    font-size: 14px;
}

// TODO To be deleted
.app-wrapper {
    .row {
        margin-right: -15px !important;
        margin-left: -15px !important;
    }
}

// App Layout Style
@media (max-width: 992px) {
    .app-wrapper {
        &.sidebar-sm {
            .header {
                margin-left: 260px !important;
            }

            .sidebar {
                left: 0px !important;
            }

            .main {
                margin-left: 260px !important;
            }
        }
    }
}

@media (min-width: 992px) {
    .app-wrapper {
        &.sidebar-sm {
            .header {
                left: 73px !important;

                .sidebar-toggle {
                    transform: rotate(180deg);
                }
            }

            .sidebar {
                max-width: 73px !important;
                min-width: 73px !important;

                .logo-wrapper {
                    .logo {
                        display: none;
                    }

                    .logo-compact {
                        display: inline !important;
                    }
                }

                .nav-item {
                    &.nav-section-title {
                        height: 0 !important;

                        .nav-item-text {
                            display: none;
                        }
                    }

                    .nav-item-text {
                        margin-left: 0px;
                        display: none;
                    }
                }

                &:hover {
                    max-width: 260px !important;
                    min-width: 260px !important;

                    .nav-item {
                        &.nav-section-title {
                            height: 44px !important;

                            .nav-item-text {
                                display: inline;
                            }
                        }

                        .nav-item-text {
                            margin-left: 8px;
                            display: inline;
                        }
                    }
                }
            }

            .main {
                padding-left: 73px !important;
            }
        }
    }
}

// Page Styling
.page-wrapper {
    padding-top: 2.5rem;

    .page-header {
        margin-bottom: 2rem;
        .page-title {
            text-transform: uppercase;
            margin-bottom: 0;
        }
    }
}

// Modal Styling
.custom-modal {
    // text-align: center;

    .modal-content {
        border: 7px solid transparentize($colorSurface, 0.2) !important;
        border-radius: 0.6rem !important;
    }

    // .modal-dialog {
    //     max-width: 100%;
    //     width: auto !important;
    //     display: inline-block;
    // }
}

// Markdown Editor Styling
.md-editor-container {
    button {
        min-width: 0;
    }
}
