// applies css to whole login page including forgetpassword, resetpassword and verify mail
.row {
    margin: 0px;
}

.component-item {
    padding: 10px 0 8px 0;
}

.vertical-auto {
    margin: auto 0;
}

// For Regular textboxes
.form-control {
    background-color: transparentize($colorOnSurface, 0.96);
}

.form-control:focus {
    background-color: transparentize($colorOnSurface, 0.96);
}

// For search text
input.search-text {
    border-right: 0px;
}

.search-text-icon {
    background-color: transparentize($colorOnSurface, 0.96);
    border: 1px solid transparentize($colorOnSurface, 0.9);
    border-left: 0px;
    border-radius: 0.25rem !important;
}

/* toggle button css */

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: none;
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}
