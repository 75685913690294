// Typography Styling

h1 {
    font-family: $fontFamilyBlack;
    font-size: 3rem;
    color: $colorPrimaryDark;
}

h2 {
    font-family: $fontFamilyBold;
    font-size: 2.5rem;
    color: $colorPrimaryDark;
}

h3 {
    font-family: $fontFamilyRegular;
    font-size: 2rem;
    color: $colorPrimaryDark;
}

h4 {
    font-family: $fontFamilyRegular;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: $colorPrimaryDark;
}

h5 {
    font-family: $fontFamilyBold;
    font-size: 1.2rem;
    color: $colorPrimaryDark;
}

h6 {
    font-family: $fontFamilyRegular;
    font-size: 1rem;
    color: $colorPrimaryDark;
}
