// Theme Variables

// Color Variables
$colorPrimary: #71ba00;
$colorOnPrimary: #ffffff;
$colorPrimaryDark: #00573f;
$colorOnPrimaryDark: #ffffff;

$colorSecondary: #0051ba;
$colorOnSecondary: #ffffff;
$colorSecondaryDark: #002d47;
$colorOnSecondaryDark: #ffffff;

$colorTertiary: #8a1b61;
$colorOnTertiary: #ffffff;
$colorTertiaryDark: #5e2751;
$colorOnTertiaryDark: #ffffff;

$colorBackground: #f6faff;
$colorOnBackground: #00573f;
$colorSurface: #ffffff;
$colorOnSurface: #002d47;

$colorDanger: #dc3545;
$colorWarning: #ffc107;

// Font Family Variables
$fontFamilyRegular: "Frutiger LT Roman";
$fontFamilyRegularI: "Frutiger LT Italic";
$fontFamilyRegularC: "Frutiger LT Condensed";
$fontFamilyRegularCI: "Frutiger LT Condensed Italic";
$fontFamilyLight: "Frutiger LT Light";
$fontFamilyLightI: "Frutiger LT Light Italic";
$fontFamilyLightC: "Frutiger LT Light Condensed";
$fontFamilyLightCI: "Frutiger LT Light Condensed Italic";
$fontFamilyBold: "Frutiger LT Bold";
$fontFamilyBoldI: "Frutiger LT Bold Italic";
$fontFamilyBoldC: "Frutiger LT Bold Condensed";
$fontFamilyBoldCI: "Frutiger LT Bold Condensed Italic";
$fontFamilyBlack: "Frutiger LT Black";
$fontFamilyBlackI: "Frutiger LT Black Italic";
$fontFamilyBlackC: "Frutiger LT Black Condensed";
$fontFamilyBlackCI: "Frutiger LT Black Condensed Italic";
$fontFamilyEBlackCI: "Frutiger LT Extra Black Condensed Italic";
$fontFamilyEBlackC: "Frutiger LT Extra Black Condensed";
$fontFamilyUBlack: "Frutiger LT Ultra Black";

/* Font size and weight */
$formFontSize: 12px;
$formFontWeight: 500;

/* Icon's size*/
$IconFontEM: 18px;
$iconFontM: 30px;
$iconFontL: 50px;
