// Bootstrap Overrides

a {
    color: $colorSecondary;

    &:hover {
        color: $colorSecondaryDark;
    }
}

.btn {
    border-radius: 0.15rem;
    text-transform: uppercase;
    font-family: $fontFamilyLight;
    box-shadow: none !important;
    min-width: 150px;

    &:focus {
        box-shadow: none;
    }

    &:active {
        box-shadow: none;
    }
}

.btn-primary {
    color: $colorOnPrimary;
    background-color: $colorPrimary;
    border-color: $colorPrimary;

    &:hover {
        color: $colorOnPrimary;
        background-color: darken($colorPrimary, 10);
        border-color: darken($colorPrimary, 10);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $colorOnPrimaryDark;
        background-color: $colorPrimaryDark;
        border-color: $colorPrimaryDark;
    }

    &:focus,
    &.focus {
        color: $colorOnPrimary;
        background-color: darken($colorPrimary, 10);
        border-color: darken($colorPrimary, 10);
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: $colorOnPrimaryDark;
        background-color: $colorPrimaryDark;
        border-color: $colorPrimaryDark;
    }
}

.btn-outline-primary {
    color: $colorPrimary;
    background-color: $colorOnPrimary;
    border-color: $colorPrimary;

    &:hover {
        color: darken($colorPrimary, 10);
        background-color: $colorOnPrimary;
        border-color: darken($colorPrimary, 10);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $colorPrimaryDark;
        background-color: $colorOnPrimaryDark;
        border-color: $colorPrimaryDark;
    }

    &:focus,
    &.focus {
        color: darken($colorPrimary, 10);
        background-color: $colorOnPrimary;
        border-color: darken($colorPrimary, 10);
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: $colorPrimaryDark;
        background-color: $colorOnPrimaryDark;
        border-color: $colorPrimaryDark;
    }
}

.btn-secondary {
    color: $colorOnSecondary;
    background-color: $colorSecondary;
    border-color: $colorSecondary;

    &:hover {
        color: $colorOnSecondary;
        background-color: darken($colorSecondary, 10);
        border-color: darken($colorSecondary, 10);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $colorOnSecondary;
        background-color: $colorSecondaryDark;
        border-color: $colorSecondaryDark;
    }

    &:focus,
    &.focus {
        color: $colorOnSecondary;
        background-color: darken($colorSecondary, 10);
        border-color: darken($colorSecondary, 10);
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: $colorOnSecondary;
        background-color: $colorSecondaryDark;
        border-color: $colorSecondaryDark;
    }
}

.btn-outline-secondary {
    color: $colorSecondary;
    background-color: $colorOnSecondary;
    border-color: $colorSecondary;

    &:hover {
        color: darken($colorSecondary, 10);
        background-color: $colorOnSecondary;
        border-color: darken($colorSecondary, 10);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $colorSecondaryDark;
        background-color: $colorOnSecondaryDark;
        border-color: $colorSecondaryDark;
    }

    &:focus,
    &.focus {
        color: darken($colorSecondary, 10);
        background-color: $colorOnSecondary;
        border-color: darken($colorSecondary, 10);
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: $colorSecondaryDark;
        background-color: $colorOnSecondaryDark;
        border-color: $colorSecondaryDark;
    }
}

// Checkbox
.custom-control-input {
    &:checked ~ .custom-control-label::before {
        color: $colorOnPrimary;
        border-color: $colorPrimary;
        background-color: $colorPrimary;
    }
}

// Toastr
.toast-container {
    .ngx-toastr {
        border-radius: 0.15rem;
    }
}

// Popup close button
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    outline: 0;
}

// Pagination
.pagination {
    .page-item {
        .page-link {
            padding: 0.4rem 0.75rem;
            margin-right: 0.6rem;
            border-radius: 3px;
            color: transparentize($colorOnBackground, 0.2);
            border: 1px solid transparentize($colorOnBackground, 0.2);
        }

        &.active {
            .page-link {
                background-color: $colorSecondary;
                border-color: $colorSecondary;
                color: $colorOnSecondary;
            }
        }

        &:focus {
            box-shadow: none;
        }
    }
}
