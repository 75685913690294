@import "scss/font"; // Custom Web Font
@import "~bootstrap/scss/bootstrap"; // Bootstrap
@import '~ngx-toastr/toastr-bs4-alert'; // Toastr
@import "scss/_variables"; // Variables

@import "./scss/main-page.scss";
@import "./scss/table-layout.scss";

@import "scss/global"; // Global Styling
@import "scss/typography"; // Typography Styling
@import "scss/bootstrap-overrides"; // Bootstrap Override Styling

// Import Quill Styling
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

a.back-label {
    margin-left: 10px;
    text-decoration: none;
    color: $colorSurface;
}

.faAngleLeft-icon {
    color: $colorSurface;
}

.faPlayCircle-icon {
    font-size: $iconFontM;
    color: $colorPrimary;
}

.faCheckCircle-icon {
    font-size: $iconFontL;
    color: $colorPrimary;
}

.faUserCircle-icon {
    font-size: $iconFontM;
    color: transparentize($colorSecondaryDark, 0.7);
}

.faTimesCircle-icon {
    font-size: $iconFontL;
    color: $colorDanger;
}

.faSpinner-icon {
    font-size: $iconFontL;
    color: $colorDanger;
}

.mandatory {
    color: $colorDanger;
}
