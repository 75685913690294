@import "./_variables.scss";

table.tbl-layout {
    color: $colorPrimaryDark;
    th {
        border-top: 0px;
        border-bottom: 0px;
        color: transparentize($colorOnSurface, 0.3);
        font-weight: 500;
        font-size: 17px;
        padding: 0.85rem;
        background-color: transparentize($colorOnSurface, 0.95);
    }

    td {
        border-top: 0px;
        border-bottom: 1px solid transparentize($colorSecondaryDark, 0.8);
    }
}
