// Webfont
@font-face {
    font-family: 'Frutiger LT Roman';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-55-Roman.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-56-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Condensed';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-57-Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-58-Condensed-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Light';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-45-Light.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Light Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-46-Light-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Light Condensed';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-47-Light-Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Light Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-48-Light-Condensed-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-65-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-66-Bold-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Bold Condensed';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-67-Bold-Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Bold Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-68-Bold-Condensed-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Black';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-75-Black.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Black Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-76-Black-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Black Condensed';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-77-Black-Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Black Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-78-Black-Condensed-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Extra Black Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-88-Extra-Black-Condensed-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Extra Black Condensed';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-87-Extra-Black-Condensed.woff') format('woff');
}

@font-face {
    font-family: 'Frutiger LT Ultra Black';
    font-style: normal;
    font-weight: normal;
    src: url('./../assets/fonts/Frutiger-LT-95-Ultra-Black.woff') format('woff');
}
